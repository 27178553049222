exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-consultation-jsx": () => import("./../../../src/pages/consultation.jsx" /* webpackChunkName: "component---src-pages-consultation-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-how-we-work-jsx": () => import("./../../../src/pages/how-we-work.jsx" /* webpackChunkName: "component---src-pages-how-we-work-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-solutions-jsx": () => import("./../../../src/pages/solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-jsx" */),
  "component---src-pages-teamplify-jsx": () => import("./../../../src/pages/teamplify.jsx" /* webpackChunkName: "component---src-pages-teamplify-jsx" */)
}

